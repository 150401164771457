import { format, isAfter, isBefore } from 'date-fns'
import * as yup from 'yup'
import { linkRegex } from '../../../config/schema'
import richContent from './richContent'

export default yup.object().shape({
  name: yup
    .string()
    .typeError('A title is required to create an event')
    .min(3, 'Event Titles should be longer than 3 characters')
    .required('A title is required to create an event'),
  description: richContent('Event description'),
  startDate: yup
    .date()
    .typeError('You must select a valid start date')
    .required('You must select a start date'),
  startTime: yup
    .date()
    .typeError('You must select a valid start time')
    .required('You must select a start time'),
  endDate: yup
    .date()
    .typeError('You must select a valid end date')
    .required('You must select a end date')
    .test('is-greater', 'Invalid date', function (value) {
      const { startDate } = this.parent
      return !isBefore(
        new Date(format(new Date(value), 'yyyy/MM/dd')),
        new Date(format(new Date(startDate), 'yyyy/MM/dd'))
      )
    }),
  endTime: yup
    .date()
    .typeError('You must select a valid end time')
    .required('You must select a valid end time')
    .test('is-greater', 'Invalid time', function (value) {
      const { startDate, endDate, startTime } = this.parent
      if (
        !isAfter(
          new Date(format(new Date(endDate), 'yyyy/MM/dd')),
          new Date(format(new Date(startDate), 'yyyy/MM/dd'))
        )
      ) {
        return isAfter(
          new Date(
            `${format(new Date(endDate), 'yyyy/MM/dd')} ${format(
              new Date(value),
              'HH:mm:ss'
            )}`
          ),
          new Date(
            `${format(new Date(startDate), 'yyyy/MM/dd')} ${format(
              new Date(startTime),
              'HH:mm:ss'
            )}`
          )
        )
      }
      return true
    }),
  onlineEvent: yup.boolean(),
  link: yup.string().when('onlineEvent', {
    is: true,
    then: yup
      .string()
      .matches(linkRegex, 'Link must be a valid url')
      .required('A link is required to create an event'),
    otherwise: yup.string().nullable(),
  }),
  location: yup.string().when('onlineEvent', {
    is: false,
    then: yup
      .string()
      .min(3, 'Location should be more than 3 characters')
      .required('A location is required to create an event'),
    otherwise: yup.string().nullable(),
  }),
  locationDescription: yup.string().when('onlineEvent', {
    is: false,
    then: yup.lazy((value) =>
      !value
        ? yup.string().nullable()
        : yup
            .string()
            .min(3, 'Location description should be more than 3 characters')
    ),
    otherwise: yup.string().nullable(),
  }),
  altText: yup.string().nullable(),
})
