import router from 'next/router'
import React, { useState } from 'react'
import useMemoizedPathFor from '../../../lib/pathFor'
import useEventCrud from '../../../lib/useEventCrud'
import { useLeavePageConfirmation } from '../../../lib/useLeavePageConfirmation'
import useUser from '../../../lib/useUser'
import Icon from '../../common/Icon'
import BaseModal from '../../common/modal/BaseModal'
import ConfirmModal from '../../common/modal/ConfirmModal'
import CreateActivityModal from '../../common/modal/CreateActivityModal'
import AddToShowcase from '../../common/popout/AddToShowcase'
import CopyButton from '../../common/popout/CopyButton'
import FollowButton from '../../common/popout/FollowButton'
import {
  Popout,
  PopoutContent,
  PopoutTrigger,
} from '../../common/popout/Popout'
import PopoutButton from '../../common/popout/PopoutButton'
import EditEvent from '../../forms/EditEvent'

const EventActions = ({ event }) => {
  const { user, isGroupManager } = useUser()
  const shareLink = `https://${window.location.host}${useMemoizedPathFor(
    'event',
    event?.slug
  )}`
  const isAuthor = user?.id === event?.owner?.id
  const isManager =
    event?.eventable_type?.toLowerCase() === 'group'
      ? isGroupManager(event?.eventable?.id)
      : false
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [activityModalVisible, setActivityModalVisible] = useState(false)
  const [modalChangesWithInput, setModalChangesWithInput] = useState(false)
  const attendeesHref = useMemoizedPathFor('attendees', event)

  const { deleteEvent } = useEventCrud()

  useLeavePageConfirmation(
    modalChangesWithInput && editVisible,
    'Are you sure you want to discard these changes?'
  )

  const onDelete = async (eventId) => {
    try {
      await deleteEvent(eventId)
      setDeleteConfirmVisible(false)
    } catch (error) {
      console.error('An unexpected error happened:', error)
    }
  }

  const toggleActivityModal = () => {
    setActivityModalVisible((prevState) => !prevState)
  }

  return (
    <Popout>
      <PopoutTrigger>
        <div className="flex flex-row items-center">
          <Icon name="more" color="offBlack" />
        </div>
      </PopoutTrigger>
      <PopoutContent>
        <CopyButton copyable={shareLink} label="Copy link" />
        {event?.status?.toLowerCase() !== 'not applicable' && (
          <FollowButton item={event} />
        )}
        <AddToShowcase
          id={event?.slug || event?.id}
          type={event?.type}
          visibility={event?.eventable?.visibility}
          badges={event?.eventable?.badges}
        />
        <PopoutButton onClick={() => router.push(attendeesHref)}>
          View attendees
        </PopoutButton>
        {isAuthor && (
          <>
            <PopoutButton onClick={() => setEditVisible(true)}>
              Edit event
            </PopoutButton>
            <PopoutButton onClick={() => toggleActivityModal()}>
              Duplicate event
            </PopoutButton>
            <EditModal
              isOpen={editVisible}
              onRequestClose={() => setEditVisible(false)}
              event={event}
              parentId={event?.id}
              modalChangesWithInput={modalChangesWithInput}
              setModalChangesWithInput={setModalChangesWithInput}
            />
            <CreateActivityModal
              toggleModal={toggleActivityModal}
              isVisible={activityModalVisible}
              postInId={event?.eventable?.id}
              postInSlug={event?.eventable?.slug}
              postInType={event?.eventable_type?.toLowerCase()}
              itemType={event?.type.toLowerCase()}
              item={event}
            />
          </>
        )}
        {(isAuthor || isManager) && (
          <>
            <PopoutButton
              className="text-topic-pink"
              onClick={() => setDeleteConfirmVisible(true)}
            >
              Delete event
            </PopoutButton>
            <ConfirmModal
              title="Delete event?"
              subtitle={
                event.eventable?.name
                  ? `Are you sure you want to permanently remove this event from ${event.eventable?.name}`
                  : `Are you sure you want to permanently remove this event`
              }
              actionLabel="Delete"
              danger
              cancelLabel="Cancel"
              action={() => onDelete(event?.slug)}
              isOpen={deleteConfirmVisible}
              onRequestClose={() => setDeleteConfirmVisible(false)}
            />
          </>
        )}
      </PopoutContent>
    </Popout>
  )
}

function EditModal({
  isOpen,
  onRequestClose,
  event,
  modalChangesWithInput,
  setModalChangesWithInput,
}) {
  const handleCloseEditModal = async () => {
    if (modalChangesWithInput && isOpen) {
      const confirm = await window.confirm(
        'Are you sure you want to discard these changes?'
      )
      if (confirm) {
        onRequestClose()
      }
    } else {
      onRequestClose()
    }
  }

  return (
    <BaseModal isOpen={isOpen} onRequestClose={handleCloseEditModal}>
      <EditEvent
        event={event}
        toggleModal={onRequestClose}
        setModalChangesWithInput={setModalChangesWithInput}
      />
    </BaseModal>
  )
}

export default EventActions
