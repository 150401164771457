export default (event) => ({
  name: event?.name || null,
  description: {
    delta: event?.content_delta,
  },
  startDate: event?.starts_at ? new Date(event?.starts_at) : new Date(),
  startTime: event?.starts_at ? new Date(event?.starts_at) : new Date(),
  endDate: event?.ends_at ? new Date(event?.ends_at) : new Date(),
  endTime: event?.ends_at ? new Date(event?.ends_at) : new Date(),
  onlineEvent: !!event?.link,
  link: event?.link,
  location: event?.location,
  locationDescription: event?.location_description,
  altText: event?.alt_text || null,
})
