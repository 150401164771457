import Link from 'next/link'
import Icon from './Icon'

const InfoItem = ({ href, icon, bgColor = 'bg-topic-green', title, text }) => (
  <Link href={href}>
    <div className="bg-white p-4 h-24 rounded shadow flex flex-row  items-center cursor-pointer">
      <div
        className={`h-full aspect-square min-h-full rounded items-center justify-center flex ${bgColor}`}
      >
        <Icon name={icon} color="white" size={18} />
      </div>
      <div className="flex flex-col gap-1/2 ml-2">
        <h2 className="font-bold">{title}</h2>
        <p className="text-gray-600 text-sm">{text}</p>
      </div>
    </div>
  </Link>
)

export default InfoItem
