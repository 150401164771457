import { getHours, getMinutes } from 'date-fns'
import React from 'react'
import Label from '../Label'

const NativeDatePicker = ({
  selected,
  onChange,
  showTimeSelectOnly,
  minDate,
  minTime,
  maxTime,
  hint,
  required,
  label,
  className,
  error,
}) => {
  let type = 'date'

  if (showTimeSelectOnly) {
    type = 'time'
  }

  return (
    <div className={`flex flex-col mb-4 mt-2 ${className}`}>
      <Label required={required}>{label}</Label>
      <input
        defaultValue={selected}
        className="bg-alt-bg rounded-md px-4 py-3 placeholder-subtitle border-0 focus:outline-none focus:ring-1 focus:ring-subtitle border-none"
        required={required}
        type={type}
        min={
          new Date(minDate) ||
          `${getHours(new Date(minTime))}:${getMinutes(new Date(minTime))}`
        }
        max={
          maxTime
            ? `${getHours(new Date(maxTime))}:${getMinutes(new Date(maxTime))}`
            : ''
        }
        onChange={onChange}
      />
      {hint && <span className="text-subtitle italic ml-4 mt-2">{hint}</span>}
      {error && <span className="text-alert ml-4 mt-2">{error}</span>}
    </div>
  )
}

export default NativeDatePicker
