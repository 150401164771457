import {
  add,
  getHours,
  getMinutes,
  isAfter,
  setHours,
  setMinutes,
} from 'date-fns'

export default function setEventEndTime(startDate, startTime, endDate) {
  if (!startDate || !startTime || !endDate) {
    return setHours(setMinutes(new Date(), 0), 0)
  }

  if (!isAfter(new Date(endDate), new Date(startDate))) {
    return setHours(
      setMinutes(new Date(), getMinutes(add(startTime, { minutes: 30 }))),
      getHours(add(startTime, { minutes: 30 }))
    )
  }

  return setHours(setMinutes(new Date(), 0), 0)
}

export function combineDateTime(date, time) {
  const value = new Date(date)
  const hours = parseInt(time.split(':')[0])
  const minutes = parseInt(time.split(':')[1])
  return setHours(setMinutes(value, minutes), hours)
}
