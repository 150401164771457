import router from 'next/router'
import pluralize from 'pluralize'
import useMemoizedPathFor from '../../../lib/pathFor'
import CLAvatar from '../../common/CLAvatar'

const EventAttendees = ({ small, highlightedUsers, count, event }) => {
  const attendeesHref = useMemoizedPathFor('attendees', event)
  let userProfiles = []

  if (event?.attendee_count <= 0) {
    return null
  }

  if (highlightedUsers?.length >= 3) {
    userProfiles = [...highlightedUsers?.slice(0, 3)]
  } else {
    userProfiles = [...highlightedUsers?.slice(0, highlightedUsers?.length)]
  }

  let attendeeCount = null

  if (count > 1) {
    attendeeCount = ` and ${count - 1} ${pluralize('other', count - 1)} going`
  } else {
    attendeeCount = ' is going'
  }

  return (
    <div
      className="w-full h-8 flex flex-row justify-start items-center space-x-1"
      role="button"
      tabIndex={0}
      onClick={() => router.push(attendeesHref)}
      onKeyDown={() => router.push(attendeesHref)}
    >
      {userProfiles?.map((user) => (
        <div key={`event-attendee-${user?.id}`} className="h-full">
          <CLAvatar
            key={`event-attendee-${user?.id}`}
            avatar={user?.avatar}
            name={user?.name}
            size={small ? 18 : 32}
            className={`rounded-full object-cover leading-gone ${
              small ? 'w-4 h-4' : 'w-8 h-8'
            }`}
          />
        </div>
      ))}
      <p className="text-sm text-disabled line-clamp-1">
        {highlightedUsers.length > 1
          ? highlightedUsers[0]?.name?.split(' ')[0]
          : null}{' '}
        {attendeeCount}
      </p>
    </div>
  )
}

export default EventAttendees
