/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useMemo, useState } from 'react'
import { components } from 'react-select'
import createPostInOptions from '../../../lib/createPostInOptions'
import useGroup from '../../../lib/useGroup'
import useGroups from '../../../lib/useGroups'
import { useLeavePageConfirmation } from '../../../lib/useLeavePageConfirmation'
import useUser from '../../../lib/useUser'
import PostInEmpty from '../../activity/PostInEmpty'
import CreateEvent from '../../forms/CreateEvent'
import CreatePost from '../../forms/CreatePost'
import Loading from '../Loading'
import Selector from '../Selector'
import PaginatedWithButton from '../pagination/PaginatedWithButton'
import BaseModal from './BaseModal'

const CreateActivityModal = ({
  toggleModal,
  isVisible,
  postInId,
  postInSlug,
  itemType = 'post',
  item,
}) => {
  const { user } = useUser()
  const {
    groups,
    isLoadingInitialData,
    isLoadingMore,
    isLoading,
    error,
    ...pagination
  } = useGroups({
    user_id: user?.id,
  })
  const { group } = useGroup(postInSlug)
  
  const postingInOptions = useMemo(
    () => createPostInOptions(groups, group),
    [isVisible, isLoading, isLoadingMore, isLoadingInitialData, postInId, group]
  )
  const [postIn, setPostIn] = useState(
    postingInOptions.find((option) => option.value === postInId) ||
      postingInOptions[0]
  )
  const [form, setForm] = useState(<CreatePost />)
  const [changesWithInput, setChangesWithInput] = useState(false)

  useLeavePageConfirmation(
    changesWithInput && isVisible,
    'Are you sure you want to discard these changes?'
  )

  useEffect(() => {
    setFormComponent()
  }, [isVisible, postIn, postInId, group])

  useEffect(() => {
    setPostIn(selectDefaultPostOption(postingInOptions, postInId))
  }, [postingInOptions?.length, isVisible, isLoading, postInId, group])

  const selectDefaultPostOption = (options, postInId) =>
    options.find((option) => option.value === postInId)

  const setFormComponent = () => {
    if (postingInOptions.length === 0) {
      setForm(<PostInEmpty />)
      return
    }

    switch (itemType?.toLowerCase()) {
      case 'post':
        setForm(
          <CreatePost
            toggleModal={toggleModal}
            postIn={postIn}
            changesWithInput={changesWithInput}
            setChangesWithInput={setChangesWithInput}
            videoEnabled={postIn?.video_enabled || user?.video_enabled}
          />
        )
        break
      case 'event':
        setForm(
          <CreateEvent
            toggleModal={toggleModal}
            postIn={postIn}
            defaultValues={item}
            changesWithInput={changesWithInput}
            setChangesWithInput={setChangesWithInput}
          />
        )
        break
      default:
        setForm(
          <CreatePost
            toggleModal={toggleModal}
            postIn={postIn}
            changesWithInput={changesWithInput}
            setChangesWithInput={setChangesWithInput}
          />
        )
        break
    }
  }

  const MenuList = (props) => (
    <components.MenuList {...props}>
      {props.children}
      <PaginatedWithButton
        {...pagination}
        buttonClassName="w-full rounded-t-none rounded-b-none justify-center"
        buttonAlt
      />
    </components.MenuList>
  )

  const handleToggleModal = async () => {
    if (changesWithInput && isVisible) {
      const confirm = await window.confirm(
        'Are you sure you want to discard these changes?'
      )
      if (confirm) {
        toggleModal()
      }
    } else {
      toggleModal()
    }
  }

  return (
    <BaseModal isOpen={isVisible} onRequestClose={handleToggleModal}>
      <div className="w-full md:min-w-600 flex flex-col px-2">
        <div className="flex flex-wrap md:justify-between md:items-center mb-3">
          {isLoadingInitialData || isLoadingMore ? (
            <Loading>Loading activity content</Loading>
          ) : (
            <>
              <span className="px-1 overflow-hidden text-offBlack items-center font-thin text-lg">
                Posting in
              </span>
              <Selector
                className="w-full md:w-5/6"
                options={postingInOptions}
                value={postIn}
                onChangeSelection={(value) => {
                  setPostIn(value)
                }}
                components={{ MenuList }}
                disabled={postInId}
              />
              {form}
            </>
          )}
        </div>
      </div>
    </BaseModal>
  )
}

export default CreateActivityModal
