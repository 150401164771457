/* eslint-disable import/no-extraneous-dependencies */
import { format, isAfter, isSameDay } from 'date-fns'
import { ICalendar } from 'datebook'
import * as FileSaver from 'file-saver'
import Image from 'next/image'
import Link from 'next/link'
import links from '../../../config/links'
import useMemoizedPathFor from '../../../lib/pathFor'
import useUser from '../../../lib/useUser'
import Breadcrumb from '../../common/Breadcrumb'
import Button from '../../common/Button'
import Container from '../../common/Container'
import Icon from '../../common/Icon'
import { useAnalytics } from '../../context/AnalyticsProvider'
import ReplyForm from '../../forms/ReplyForm'
import AwardStampRow from '../../stamps/AwardStampRow'
import TopicsRow from '../../topics/TopicsRow'
import ActivityContent from '../ActivityContent/ActivityContent'
import Replies from '../Replies'
import ReplyCTA from '../ReplyCTA'
import UpvoteButton from '../UpvoteButton'
import EventActions from './EventActions'
import EventAttendees from './EventAttendees'
import EventRSVP from './EventRSVP'

const Event = ({
  event,
  dontShowAll,
  archived = false,
  collapsable = true,
  referrer,
}) => {
  const { trackData } = useAnalytics()
  const { user } = useUser()
  const eventHref = useMemoizedPathFor('event', event)
  const ownerHref = useMemoizedPathFor('user', event?.owner)
  const eventableLink = useMemoizedPathFor(
    event?.eventable_type,
    event?.eventable?.slug || event?.eventable?.id
  )
  const eventDate = isSameDay(
    new Date(event?.starts_at),
    new Date(event?.ends_at)
  )
    ? `${format(
        new Date(event?.starts_at),
        'EEE do MMM yy h:mmbbb'
      )} - ${format(new Date(event?.ends_at), 'h:mmbbb')}`
    : `${format(new Date(event?.starts_at), 'EEE do MMM yy h:mmbbb')}`
  const icalendar = new ICalendar({
    title: event?.name,
    location: event?.location || event?.link,
    description: event?.description,
    start: new Date(event?.starts_at),
    end: new Date(event?.ends_at),
  })

  const isEventInPast = isAfter(new Date(), new Date(event?.ends_at))

  const downloadCalendar = () => {
    const ics = icalendar.render()
    const blob = new Blob([ics], {
      type: 'text/calendar',
    })

    const fileName = event?.name ? `${event?.name}.ics` : 'my-calendar-event.ics'

    FileSaver.saveAs(blob, fileName)

    trackData('event_add_to_calendar', {
      type: 'event',
      item: event,
      user,
    })
  }

  return (
    <Container noBottomPadding>
      <div className="p-0 flex flex-col w-full justify-start rounded-none bg-white lg:rounded-lg mb-4">
        {referrer && <Breadcrumb referrer={referrer} />}
        {event?.image && (
          <div className="w-full h-72 overflow-hidden rounded-none bg-white lg:rounded-t-lg relative">
            <Image
              key={`${event?.alt_text || event?.name}=event-image`}
              src={event?.image}
              alt={event?.alt_text || event?.name}
              className="object-cover"
              fill
              sizes="100vw"
            />
          </div>
        )}
        <div className="w-full pt-7 pb-0">
          <div className="px-3 md:px-6">
            <div className="w-full flex flex-row justify-between items-start mb-5">
              <div className="flex flex-row gap-3">
                <UpvoteButton
                  hasVoted={event.user_has_upvoted}
                  type="Event"
                  id={event.id}
                  itemSlug={event.slug}
                  count={event.upvote_count}
                  item={event}
                />
                <h2 className="font-bold text-lg md:text-xl line-clamp-3 break-words">
                  {event?.name}
                </h2>
              </div>
              {!archived && <EventActions event={event} />}
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between mb-4 justify-items-start md:items-center space-y-2 md:space-y-0">
              <div className="w-72">
                <EventAttendees
                  key={`${event.slug}-event-attendees`}
                  event={event}
                  highlightedUsers={event?.highlighted_users}
                  count={event?.attendee_count}
                />
              </div>
              <div className="flex mt-4 md:mt-0 flex-row gap-3 align-middle items-center">
                {!archived && (
                  <Button
                    onClick={downloadCalendar}
                    noPadding
                    tiny
                    className="px-3 py-2 text-offBlack"
                  >
                    <Icon name="plus" color="disabled" size={20} />
                    Add to Calendar
                  </Button>
                )}
                <div className="w-36">
                  {event?.status?.toLowerCase() !== 'not applicable' &&
                    !archived &&
                    !isEventInPast && (
                      <EventRSVP
                        eventSlug={event?.slug}
                        status={event?.status}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between mb-0 md:mb-2 justify-items-start md:items-center">
              {event?.starts_at && (
                <InfoItem href={eventHref} name="calendar" value={eventDate} />
              )}
              {event?.location && (
                <InfoItem
                  href={encodeURI(`${links.mapProvider}${event.location}`)}
                  name="map-pin"
                  value={`${event?.location} ${event?.location_description}`}
                />
              )}
              {event?.link && (
                <InfoItem
                  href={event?.link}
                  name="link"
                  value={event?.link}
                  breakAll
                />
              )}
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between mb-4 justify-items-start md:items-center">
              <InfoItem
                href={ownerHref}
                name="profile"
                value={`By ${event?.owner?.name}`}
              />
              <InfoItem
                href={eventableLink}
                name="group"
                value={`${event?.eventable?.name}`}
              />
            </div>

            <p className="font-bold text-black text-sm mb-3">
              About this event
            </p>
            <ActivityContent item={event} collapsable={collapsable} />

            <AwardStampRow
              itemStamps={event?.stamps}
              stampableId={event?.id}
              stampableType="Event"
              canAwardStamp={event?.owner.id !== user?.id}
              item={event}
            />
          </div>
          <div className="px-2 md:px-4 mt-3">
            <TopicsRow topics={event?.topics} alt />
          </div>
          <hr className="my-4" />
          {event?.reply_count !== 0 ? (
            <div className="px-4">
              <Replies
                count={event?.reply_count}
                replies={event?.replies}
                parentId={event?.id}
                parentSlug={event?.slug}
                parentType="Event"
                groupId={event?.eventable?.id}
                dontShowAll={dontShowAll}
                disableReplyActions={archived}
              />
            </div>
          ) : (
            <div className="px-3 md:px-4">
              <ReplyCTA itemArchived={archived} />
            </div>
          )}
          {!archived && (
            <div className="px-2 md:px-4">
              <ReplyForm
                itemId={event?.slug}
                itemType={event?.type}
                item={event}
                videoEnabled={
                  event?.eventable?.video_enabled || user?.video_enabled
                }
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

function EventIcon({ name }) {
  return (
    <div className="bg-gray-100 p-2 rounded">
      <Icon name={name} color="disabled" size={28} />
    </div>
  )
}

function InfoItem({ href, name, value, breakAll }) {
  return (
    <Link href={href} legacyBehavior>
      <div className="flex flex-row my-2 items-center cursor-pointer w-fill md:w-1/2">
        <EventIcon name={name} />
        <span
          className={`mx-2 line-clamp-2 text-sm ${
            breakAll ? 'break-all' : 'break-word'
          }`}
        >
          {value}{' '}
        </span>
      </div>
    </Link>
  )
}

export default Event
