import Link from 'next/link'
import { useRef } from 'react'
import useEvent from '../../../lib/useEvent'
import useEventCrud from '../../../lib/useEventCrud'
import useUser from '../../../lib/useUser'
import Icon from '../../common/Icon'
import {
  Popout,
  PopoutContent,
  PopoutTrigger,
} from '../../common/popout/Popout'
import PopoutButton from '../../common/popout/PopoutButton'
import { useAnalytics } from '../../context/AnalyticsProvider'
import usePopoutTriggerRef from '../../../lib/usePopoutTriggerRef'

const EventRSVP = ({ eventSlug, status }) => {
  const { popoutTriggerRef, popoutIsOpen } = usePopoutTriggerRef()
  const { event } = useEvent(popoutIsOpen ? eventSlug : null)
  const { user } = useUser()
  const { createEventAttendance, updateEventAttendance } = useEventCrud()
  const { trackData } = useAnalytics()

  const submitAttendance = (response) => {
    if (status === 'going' || status === 'declined') {
      updateEventAttendance(user?.id, eventSlug, response)
    } else {
      createEventAttendance(user.id, eventSlug, response)
    }

    trackData('event_rsvp', {
      user,
      type: 'event',
      item: event,
      status: response,
    })
  }

  let statusButton = <RSVPButton color="bg-topic-blue" text="RSVP" />
  switch (status?.toLowerCase()) {
    case 'going':
      statusButton = (
        <RSVPButton icon="check" color="bg-topic-green" text="Going" />
      )
      break
    case 'declined':
      statusButton = (
        <RSVPButton icon="times" color="bg-topic-pink" text="Not Going" />
      )
      break
    default:
      statusButton = <RSVPButton color="bg-topic-blue" text="RSVP" />
      break
  }

  if (!user) {
    return (
      <div className="bg-topic-green text-white text-center px-1 py-1 rounded-3xl">
        <Link href="/login">Login to RSVP</Link>
      </div>
    )
  }

  return (
    <Popout>
      <PopoutTrigger ref={popoutTriggerRef}>{statusButton}</PopoutTrigger>
      <PopoutContent>
        <PopoutButton onClick={() => submitAttendance('going')}>
          Going
        </PopoutButton>
        <PopoutButton onClick={() => submitAttendance('declined')}>
          Not Going
        </PopoutButton>
      </PopoutContent>
    </Popout>
  )
}

export default EventRSVP

const RSVPButton = ({ icon, color, text }) => (
  <div
    className={`flex flex-row justify-center items-center py-1 ${color} rounded-lg`}
  >
    {icon && <Icon name={icon} size={20} color="white" className="mr-2" />}
    <p className="text-white">{text}</p>
  </div>
)
