import CLAvatar from '../components/common/CLAvatar'

const ImageLabel = (avatar, name) => (
  <div className="flex flex-row items-center cursor-pointer">
    <div className="h-7 w-7">
      <CLAvatar
        key={`${name}-cpio-avatar`}
        avatar={avatar}
        alt={`${name} avatar`}
        layout="fixed"
        size={28}
        name={name}
        className="rounded-full bg-gray-400 h-7 w-7 flex-none"
      />
    </div>
    <span className="mx-1 overflow-ellipsis overflow-hidden whitespace-nowrap flex-initial">
      {name}
    </span>
  </div>
)

export default function createPostInOptions(groups, group) {
  const postingInOptions = []

  if (group) {
    postingInOptions.push({
      value: group?.id,
      avatar: group?.avatar,
      label: ImageLabel(group?.avatar, group?.name),
      postable_type: 'Group',
      video_enabled: group?.video_enabled,
    })
  }

  if (groups && !group) {
    groups.map((group) =>
      postingInOptions.push({
        value: group?.id,
        avatar: group?.avatar,
        label: ImageLabel(group?.avatar, group?.name),
        postable_type: 'Group',
        video_enabled: group?.video_enabled,
      })
    )
  }

  return postingInOptions
}
