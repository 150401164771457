import useSWR from 'swr'
import createFormData from './createFormData'
import fetchJson from './fetchJson'
import useMutators from './useMutators'

export default function useEvent(id) {
  const url = id != null ? `/api/events/${id}.json` : null
  const { error, data, mutate: mutateEvent, isValidating } = useSWR(url)
  const event = data?.event

  const api = useMutators(mutateEvent, {
    updateEvent,
    deleteEvent,
    createEventAttendance,
    updateEventAttendance,
  })

  return {
    event,
    mutateEvent,
    isValidating,
    error,
    ...api,
    createEvent,
  }
}

export async function createEvent(event) {
  const formData = createFormData({ event })

  return await fetchJson(`/api/events.json`, {
    method: 'POST',
    body: formData,
  })
}

export async function updateEvent(event) {
  const formData = createFormData({ event })

  return await fetchJson(`/api/events/${event.id}.json`, {
    method: 'PUT',
    body: formData,
  })
}

export async function deleteEvent(eventId) {
  return await fetchJson(`/api/events/${eventId}.json`, {
    method: 'DELETE',
  })
}

export async function createEventAttendance(userId, eventId, status) {
  const body = {
    event: {
      status,
      id: eventId,
    },
  }
  return await fetchJson(`/api/users/${userId}/events.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
}

export async function updateEventAttendance(userId, eventId, status) {
  return await fetchJson(`/api/users/${userId}/events/${eventId}.json`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      event: {
        status,
      },
    }),
  })
}
