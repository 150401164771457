import usePaginated from './api/usePaginated'

export default function useGroups(filters = {}) {
  const { items, ...attributes } = usePaginated(
    () => '/api/groups',
    (response) => response?.groups,
    filters
  )
  return {
    groups: items?.sort((x, y) => (x.name < y.name ? -1 : 1)),
    ...attributes,
  }
}
