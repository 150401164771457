import classnames from 'classnames'
import getUserLocale from 'get-user-locale'
import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Icon from '../Icon'

const ReactDatePicker = ({
  selected,
  onChange,
  showTimeSelect,
  showTimeSelectOnly,
  dateFormat,
  placeholderText,
  minDate,
  minTime,
  maxTime,
  icon,
  hint,
  required,
  label,
  className,
  error,
}) => {
  const userLocale = getUserLocale()

  const CustomDatePickerInput = forwardRef(
    ({ value, onClick, placeholderText, icon = 'calendar' }, ref) => (
      <button
        className="w-full mr-2 px-3 py-3 rounded font-semibold text-off-black flex-row flex flex-shrink-0 justify-between items-center cursor-pointer bg-alt-bg hover:bg-gray-200"
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        ref={ref}
      >
        {value || placeholderText}
        <span>
          <Icon name={icon} size={24} />
        </span>
      </button>
    )
  )

  const wrapperStyle = classnames(
    'flex flex-shrink-0 flex-col mb-6 mt-2 w-1/2',
    className
  )

  return (
    <div className={wrapperStyle}>
      <label className="font-bold mb-2">
        {label}
        {required && <span className="text-topic-pink ml-2">*</span>}
      </label>
      <DatePicker
        selected={selected}
        onChange={onChange}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={5}
        locale={userLocale}
        dateFormat={dateFormat}
        minDate={minDate}
        minTime={minTime}
        maxTime={maxTime}
        customInput={
          <CustomDatePickerInput
            placeholderText={placeholderText}
            icon={icon}
          />
        }
      />
      {hint && <span className="text-subtitle italic ml-4 mt-2">{hint}</span>}
      {error && <span className="text-alert ml-4 mt-2">{error}</span>}
    </div>
  )
}

export default ReactDatePicker
