import InfoItem from '../common/InfoItem'

const PostInEmpty = () => (
  <div className="flex flex-col w-full mb-4 px-2 md:px-0">
    <div className="bg-white rounded-lg p-4 mb-4">
      <p className="text-sm text-gray-600 my-1">
        It's great you want to dive in and create content. First you need to
        join some groups. I would recommend heading to the discover tab to find
        some groups that interest you.
      </p>
    </div>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full mb-2">
      <InfoItem
        href="/discover"
        icon="discover"
        title="View the discover tab"
        bgColor="bg-topic-yellow"
        text="Find a community in from discover"
      />
      <InfoItem
        href="/search"
        icon="search"
        title="Use Search"
        bgColor="bg-topic-purple"
        text="Use Search to find a group"
      />
    </div>
  </div>
)

export default PostInEmpty
