import 'react-datepicker/dist/react-datepicker.css'
import NativeDatePicker from './datePicker/NativeDatePicker'
import ReactDatePicker from './datePicker/ReactDatePicker'

const CLDatePicker = ({ ...props }) => {
  if (window.Modernizr?.inputtypes) {
    return <NativeDatePicker {...props} />
  }
  return <ReactDatePicker {...props} />
}

export default CLDatePicker
