import * as yup from 'yup'
import { linkRegex } from '../../../config/schema'
import richContent from './richContent'

export default yup.object().shape({
  title: yup
    .string()
    .typeError('A title is required to make a post')
    .min(3, 'Post Titles should be longer than 3 characters')
    .required('A title is required to make a post'),
  content: richContent(),
  link: yup.lazy((value) =>
    !value
      ? yup.string().nullable()
      : yup.string().matches(linkRegex, 'website must be a valid url')
  ),
  altText: yup.string().nullable(),
})
