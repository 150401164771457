export default function Checkbox({
  name,
  value,
  onChange,
  children,
  register,
  className,
  backgroundColor = 'white',
}) {
  return (
    <label className={`flex items-center ${className}`}>
      <input
        name={name}
        type="checkbox"
        className={`form-checkbox h-5 w-5 text-topic-green rounded-md focus:outline-none bg-${backgroundColor}`}
        checked={value}
        onChange={
          onChange
            ? (e) => {
                onChange(e.target.checked)
              }
            : null
        }
        {...register(name)}
      />
      <span className="ml-4 text-black">{children}</span>
    </label>
  )
}
