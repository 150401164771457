import { useSWRConfig } from 'swr'
import { useCacheKey } from '../components/context/CacheKey'
import eventCache from './cache/eventCache'
import {
  createEvent,
  createEventAttendance,
  deleteEvent,
  updateEvent,
  updateEventAttendance,
} from './useEvent'
import useUser from './useUser'

export default function useEventCrud() {
  const { cache, mutate } = useSWRConfig()
  const cacheKey = useCacheKey()
  const { user } = useUser()

  return {
    updateEvent: async (event) => {
      const response = await updateEvent(event)
      eventCache(cache, mutate, cacheKey, event.slug).update(
        () => ({
          ...response.event,
        }),
        user?.id
      )
    },
    deleteEvent: async (eventSlug) => {
      await deleteEvent(eventSlug)
      eventCache(cache, mutate, cacheKey, eventSlug).remove(user?.id)
    },
    createEvent: async (event) => {
      const response = await createEvent(event)
      eventCache(cache, mutate, cacheKey).add(response.event)

      return response
    },
    createEventAttendance: async (userId, eventId, status) => {
      const response = await createEventAttendance(userId, eventId, status)
      eventCache(cache, mutate, cacheKey, eventId).update(
        () => ({
          ...response.event,
        }),
        user?.id
      )
    },
    updateEventAttendance: async (userId, eventId, status) => {
      const response = await updateEventAttendance(userId, eventId, status)
      eventCache(cache, mutate, cacheKey, eventId).update(
        () => ({
          ...response.event,
        }),
        user?.id
      )
    },
  }
}
