import React from 'react'
import Select from 'react-select'
import colors from '../../config/colors'

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: colors.black,
    border: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.black,
  }),
}

const Selector = ({
  options,
  onChangeSelection,
  value,
  label,
  labelLeft = false,
  hint,
  required,
  className,
  components,
  searchable = false,
  backgroundColor = 'alt-bg',
  disabled = false,
}) => (
  <div className={className}>
    {label && (
      <label className={`font-bold mb-2 ${labelLeft ? 'ml-0' : 'ml-3'}`}>
        {label}
        {required && <span className="text-topic-pink ml-2">*</span>}
      </label>
    )}
    <Select
      styles={customStyles}
      options={options}
      onChange={onChangeSelection}
      defaultValue={value}
      components={components}
      isSearchable={searchable}
      className={`rounded-lg bg-${backgroundColor}`}
      isDisabled={disabled}
    />
    {hint && <span className="text-subtitle italic ml-4 mt-2">{hint}</span>}
  </div>
)

export default Selector
