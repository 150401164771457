import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/nextjs'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useSWRConfig } from 'swr'
import { pathFor } from '../../lib/pathFor'
import usePostCrud from '../../lib/usePostCrud'
import useUser from '../../lib/useUser'
import BasicErrorMessage from '../common/BasicErrorMessage'
import Button from '../common/Button'
import CLEditor from '../common/CLEditor'
import TextInput from '../common/TextInput'
import { useAnalytics } from '../context/AnalyticsProvider'
import { useCacheKey } from '../context/CacheKey'
import ResourceRow from './common/ResourceRow/ResourceRow'
import postSchema from './schemas/post'

export default function CreatePost({
  toggleModal,
  postIn,
  changesWithInput,
  setChangesWithInput,
  videoEnabled,
}) {
  const { trackData } = useAnalytics()
  const { mutate } = useSWRConfig()
  const cacheKey = useCacheKey()
  const [submitting, setSubmitting] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [image, setImage] = useState(null)
  const [file, setFile] = useState(null)
  const [videoId, setVideoId] = useState(null)

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(postSchema),
    defaultValues: {
      title: null,
      image: null,
      isFile: false,
      file: null,
      content: null,
      isLink: false,
      link: null,
      altText: null,
      isVideo: false,
    },
  })
  const watchFields = useWatch({ control })
  const { createPost } = usePostCrud()
  const { user } = useUser()

  useEffect(() => {
    if (
      (watchFields?.title ||
        watchFields?.content ||
        watchFields?.link ||
        image ||
        file) &&
      !submitting
    ) {
      setChangesWithInput(true)
    } else {
      setChangesWithInput(false)
    }
  }, [watchFields, image, file])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  const onSubmit = async (data, e) => {
    if (!postIn?.value) {
      setErrorMsg('Please select a group to post in')
      return
    }

    setSubmitting(true)
    setChangesWithInput(false)
    setErrorMsg(null)
    const { title, content, link, altText } = data

    const body = {
      title,
      content_html: content.html,
      content_delta: JSON.stringify(content.delta),
      postable_type: postIn?.postable_type,
      postable_id: postIn?.value,
      link,
      image: image?.raw,
      file: file?.raw,
      type: 'Post',
      alt_text: altText,
      video_id: videoId,
    }

    try {
      const { post } = await createPost(body)
      mutate(cacheKey.value)
      e.target.reset()
      toggleModal()
      if (post) {
        trackData('post_created', {
          user,
          item: post,
          type: 'post',
        })
        Router.push(pathFor('group', `${post.postable?.slug}/activity`))
      }
      setSubmitting(false)
    } catch (error) {
      Sentry.captureException(error)
      console.error('An unexpected error happened:', error)
      setErrorMsg('There was an error creating your post')
      setSubmitting(false)
      setSubmitting(true)
    }
  }

  const handleDiscardPost = async () => {
    if (changesWithInput) {
      const confirm = await window.confirm(
        'Are you sure you want to discard this post? from handleDiscardPost in CreatePost.js'
      )
      if (confirm) {
        toggleModal()
        reset()
      }
    } else {
      toggleModal()
      reset()
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1 flex-col w-full"
    >
      <TextInput
        name="title"
        label="Post Title"
        required
        hint={errors?.title?.message}
        register={register}
      />
      <Controller
        name="content"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CLEditor
            value={value?.delta}
            onChange={onChange}
            label="Post Text"
            required
            hint={errors?.content?.message || errors?.content?.html?.message}
            topicHint
            delta
            groupId={postIn?.value}
          />
        )}
      />

      <ResourceRow
        errors={errors}
        register={register}
        setImage={setImage}
        image={image}
        setFile={setFile}
        file={file}
        getValues={getValues}
        setValue={setValue}
        videoId={videoId}
        setVideoId={setVideoId}
        allowVideoUpload={videoEnabled}
      />

      <BasicErrorMessage error={errorMsg} />

      <div className="flex flex-row justify-end mt-3">
        <Button
          noPadding
          className="mr-2 px-5 py-2 text-off-black"
          onClick={handleDiscardPost}
        >
          Discard
        </Button>
        <input
          type="submit"
          value="Post"
          disabled={
            (watchFields?.isFile && !file && !image) ||
            (watchFields?.isLink && !watchFields?.link) ||
            (watchFields?.isVideo && videoId === null) ||
            submitting ||
            !postIn?.value
          }
          className={`px-8 py-2 rounded-full text-white bg-primary  ${
            (watchFields?.isFile && !file && !image) ||
            (watchFields?.isLink && !watchFields?.link) ||
            (watchFields?.isVideo && videoId === null) ||
            !postIn?.value
              ? 'opacity-20 cursor-not-allowed'
              : 'opacity-100 cursor-pointer'
          }`}
        />
      </div>
    </form>
  )
}
